.cart-icon-container {
  position: relative;
  cursor: pointer;
}

.cart-dropdown {
  display: none;
  position: absolute;
  left: 0; /* Align the dropdown to the right */
  top: 100%; /* Position it directly below the cart icon */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 15px;
  width: 240px; /* Width can be adjusted based on content */
  z-index: 100;
  color: #333;
}

.cart-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cart-dropdown li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.cart-total {
  margin-top: 12px;
  font-weight: bold;
}

.remove-item {
  cursor: pointer;
  color: red;
  margin-left: 10px;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cart-item-img {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.cart-item-name {
  flex-grow: 1;
}

.cart-item-quantity {
  margin: 0 10px;
}

.cart-item-remove {
  cursor: pointer;
  color: #ff0000;
  margin-left: 10px;
}

.cart-total,
.cart-dropdown button {
  display: block; /* Ensure total and buttons are on new lines */
  text-align: center; /* Center align the text */
  margin-top: 10px; /* Add margin for spacing */
}

.clear-cart-btn {
  background-color: #6a1b9a;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}

.place-order-btn {
  background-color: #4caf50; /* A green color */
  color: white; /* White text */
  padding: 10px 20px;
  margin-top: 10px; /* Spacing from the clear cart button */
  border: none;
  cursor: pointer;
  width: 100%; /* Full width */
}

.place-order-btn:hover {
  background-color: #45a049; /* Darker green on hover */
}

.content {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.content-sub-service {
  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.card-container {
  /* flex: 1 1 auto; */
  text-align: center;
  width: 100px;
  height: 170px;
  margin: 30px;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.card {
  background-size: cover;
  background-position: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  width: 70px; /* Fixed size for round shape */
  height: 70px; /* Fixed size for round shape */
  margin: 10px;
  padding: 20px;
  text-align: center;
  color: #333;
  border-radius: 50%; /* Make it round */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
}

.card-container:hover .card {
  transform: translateY(-10px); /* Lift the card up on hover */
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3); /* Larger shadow for lifted effect */
}

.service-name {
  background-color: #6a1b9a; /* Replace with your color */
  color: #ffffff; /* Replace with your color */
  padding: 10px; /* Adjust the padding to your preference */
  border-radius: 20px; /* Adjust the border-radius to your preference */
  display: flex; /* Flexbox layout */
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  width: 100px; /* Set a specific width */
  height: 20px; /* Set a specific height */
  text-align: center; /* This will center the text horizontally */
  font-weight: bold; /* Adjust as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust as needed */
  text-transform: uppercase; /* Adjust as needed */
  font-size: 10px; /* Adjust as needed */
  cursor: pointer; /* Changes the cursor to indicate clickability */
}

.service-name-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@media (max-width: 540px) {
  .cart-dropdown {
    right: 0;
    left: auto;
  }

  .content-sub-service {
    margin: 30px;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 140px;
    margin: 20px;
    max-width: calc(40% - 20px);
  }

  .service-name {
    width: 70px;
    font-size: 8px;
    /* padding: 5px; */
    align-items: center;
    margin: 5px;
  }

  .card {
    width: 40px;
    height: 40px;
    /* padding: 5px; */
    align-content: center;
  }
}

@media (max-width: 280px) {
  .content-sub-service {
    width: 100%;
  }

  .card-container {
    /* Calculate the width by dividing the viewport width by 3, minus the total margin */
    max-width: calc(50% - 10px); /* 3 in a row, adjust margin as needed */
    width: 100%; /* Or adjust based on the content */
    height: 100%; /* Or adjust based on the content */
    margin: 5px; /* Reduce margins to fit 3 in a row */
  }

  .service-name {
    width: 60px;
    font-size: 8px;
    /* padding: 5px; */
    align-items: center;
    margin: 0;
  }

  .card {
    width: 40px;
    height: 40px;
    /* padding: 5px; */
    align-content: center;
  }

  .card-container a {
    width: max-content;
    height: auto;
  }
}

@media (max-width: 1024px) {
  .content-sub-service {
    margin: 30px;
    width: 100%;
  }
}

/* .card1 {
  background-image: url("../ESC images/home cleaning services icons/1.png");
}
.card2 {
  background-image: url("../ESC images/home cleaning services icons/2.png");
}
.card3 {
  background-image: url("../ESC images/home cleaning services icons/3.png");
}
.card4 {
  background-image: url("../ESC images/home cleaning services icons/4.png");
}
.card5 {
  background-image: url("../ESC images/home cleaning services icons/5.png");
}
.card6 {
  background-image: url("../ESC images/home cleaning services icons/6.png");
}
.card7 {
  background-image: url("../ESC images/home cleaning services icons/7.png");
}
.card8 {
  background-image: url("../ESC images/home cleaning services icons/8.png");
}
.card9 {
  background-image: url("../ESC images/home cleaning services icons/9.png");
}
.card10 {
  background-image: url("../ESC images/home cleaning services icons/10.png");
}
.card11 {
  background-image: url("../ESC images/home cleaning services icons/11.png");
}
.card12 {
  background-image: url("../ESC images/home cleaning services icons/12.png");
}
.card13 {
  background-image: url("../ESC images/home cleaning services icons/13.png");
}
.card14 {
  background-image: url("../ESC images/home cleaning services icons/14.png");
}
.card15 {
  background-image: url("../ESC images/home cleaning services icons/15.png");
}
.card16 {
  background-image: url("../ESC images/home cleaning services icons/16.png");
}
.card17 {
  background-image: url("../ESC images/home cleaning services icons/17.png");
}
.card18 {
  background-image: url("../ESC images/home cleaning services icons/18.png");
}

.card19 {
  background-image: url("../ESC images/Furnished Home.jpg");
}

.card20 {
  background-image: url("../ESC images/Unfurnished Home.jpg");
}

.card21 {
  background-image: url("../ESC images/Furnished Villa.jpg");
}

.card22 {
  background-image: url("../ESC images/Unfurnished Villa.jpg");
}

.card23 {
  background-image: url("../ESC images/home cleaning services icons/18.png");
} */

.card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow-y: auto; /* Enable vertical scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-active {
  display: block;
}

.modal-overlay {
  position: fixed;
  z-index: -100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
}

/* Modal Content */
.modal-content {
  font-family: "Open Sans", sans-serif;
  color: #4a4a4a;
  background-color: #fff;
  margin: 5% auto; /* Centers the modal */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.1);
  width: 80%; /* Responsive width */

  transition: all 0.3s ease-in-out; /* Smooth transition for all changes */
}

.pricing-cards-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
}

.pricing-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 20px;
  width: calc(20% - 60px); /* Adjust width according to your design */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.pricing-card img {
  max-width: 100%;
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.pricing-card h3 {
  margin-top: 15px;
}

.price {
  color: #6a1b9a;
  font-size: 1.5em;
  font-weight: bold;
  margin: 10px 0;
}

.add-to-cart-btn {
  background-color: #ff5722; /* Vibrant color for call-to-action */
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.add-to-cart-btn:hover {
  background-color: #e64a19; /* Darker shade for hover effect */
}

@media (max-width: 768px) {
  .pricing-cards-container {
    flex-direction: column;
    align-items: center;
  }

  .pricing-card {
    width: 90%; /* Adjust the width as per your design */
    margin-bottom: 20px; /* Space between stacked cards */
  }
}

.cleaning-plan-overview {
  background-color: #fff; /* Background color */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 5px #6a1b9a; /* Initial shadow */
  padding: 20px; /* Padding inside the card */
  margin: 20px 20px; /* Margin around the card */
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for shadow */
}

.cleaning-plan-overview:hover {
  box-shadow: 0 5px 15px #6a1b9a; /* Shadow effect on hover */
}

.cleaning-plan-overview h3 {
  color: #6a1b9a; /* Color for the header */
  margin-bottom: 16px; /* Space below the header */
}

.cleaning-plan-overview p {
  color: #333; /* Color for paragraphs */
  line-height: 1.6; /* Line height for readability */
}

.cleaning-plan-overview ul {
  list-style: none; /* Remove default list styling */
  padding-left: 20px; /* Padding for list */
}

.cleaning-plan-overview li::before {
  content: "• "; /* Bullet point */
  color: #6a1b9a; /* Color for bullet point */
  font-weight: bold; /* Make bullet point bold */
  display: inline-block; /* Needed to add space after bullet */
  width: 1em; /* Space after bullet */
  margin-left: -1em; /* Align bullets with the text */
}

/* If you have additional specific classes for sections like Bathroom, Balcony, etc., add their styles here. */
.cleaning-plan-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px; /* Space between the cards */
  margin: 20px;
}

.cleaning-plan-card {
  position: relative; /* Needed for absolute positioning of children */
  background-color: #f4f4f4; /* Background color */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 5px #6a1b9a; /* Initial shadow */
  padding: 20px; /* Padding inside the card */
  flex-basis: calc(25% - 20px); /* Four cards per row, adjusting for the gap */
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transition for shadow */
  display: flex; /* Enables flexbox within the card */
  flex-direction: column; /* Stacks children vertically */
  justify-content: space-between; /* Spaces children out */
}

.cleaning-plan-card:hover {
  background: linear-gradient(
    #6a1b9a,
    #3743d1
  ); /* Change background color on hover */
  box-shadow: 5 5px 5px rgba(0, 0, 0, 0.3); /* Shadow effect on hover */
}

.cleaning-plan-card strong {
  color: #000; /* Sets the text color to black */
}

.cleaning-plan-card:hover strong {
  color: #fff; /* Keep the strong text black on hover */
}

.cleaning-plan-card h3 {
  color: #6a1b9a; /* Color for the header */
  margin-bottom: 16px; /* Space below the header */
}

.cleaning-plan-card ul {
  list-style: none; /* Remove default list styling */
  padding-left: 20px; /* Padding for list */
  color: #888;
}

.cleaning-plan-card:hover h3,
.cleaning-plan-card:hover ul {
  color: #fff; /* Change text color to white on hover */
}

.cleaning-plan-card li::before {
  content: "• "; /* Bullet point */
  color: #6a1b9a; /* Color for bullet point */
  font-weight: bold; /* Make bullet point bold */
  display: inline-block; /* Needed to add space after bullet */
  width: 1em; /* Space after bullet */
  margin-left: -1em; /* Align bullets with the text */
}

.cleaning-plan-card:hover li::before {
  color: #fff; /* Change bullet point color to white on hover */
}

.modal-trigger {
  align-self: flex-end; /* Aligns to the right */
  margin-top: auto; /* Pushes to the bottom */
  color: #6a1b9a; /* Gives it a distinct color */
  cursor: pointer; /* Changes cursor on hover */
  text-decoration: underline; /* Underlines text to indicate it's clickable */
}

/* Adjust this class as necessary to style the text within .modal-trigger */
.modal-trigger strong {
  font-weight: normal; /* Adjust as necessary */
}

.Exclusions {
  margin: 30px;
}

.Exclusions hr {
  border: none; /* Remove the default border */
  height: 4px; /* Thickness of the HR line */
  border-radius: 2px; /* Rounded corners for the HR line */
  background: linear-gradient(
    to right,
    #6a1b9a,
    #985fbc,
    #6a1b9a
  ); /* Gradient effect */
  margin: 10px 0; /* Spacing above and below the HR line */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow below the HR line */
}

@media (max-width: 768px) {
  .cleaning-plan-container {
    flex-direction: column;
  }

  .cleaning-plan-card {
    flex-basis: 100%;
    margin-bottom: 20px; /* Add space between the cards when stacked */
  }
}

/* The Close Button */
.close {
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Service Details - Header Styles */
.header1 {
  color: #6a1b9a; /* Highlight color */
  margin: 10px 0;
  font-size: 1.75rem;
}

.sub-header {
  color: #333; /* Dark grey for readability */
  font-size: 1.5rem;
}

.sub-sub-header {
  color: #333;
  font-size: 1.25rem;
  margin-bottom: 10px;
}

/* Detail Info - Image and Text Side by Side */
.service-details {
  background: #f8f8f8; /* Subtle background color */
  border-radius: 8px;
  margin-top: 20px; /* Spacing from the top */
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
  display: flex;
  flex-wrap: wrap; /* Wraps content on smaller screens */
  justify-content: center; /* Center alignment */
  gap: 20px; /* Spacing between image and text */
}

.service-image,
.service-text {
  flex: 1; /* Flex grow equally */
  max-width: 45%; /* Maximum width */
  margin-right: 20px; /* Space between */
}

.service-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(106, 27, 154, 0.6);
}

.service-text h1,
.service-text h2,
.service-text h3 {
  font-family: "Roboto", sans-serif; /* Header font */
  color: #5d3b9e; /* Theme color for headers */
}

.service-text {
  max-width: 600px; /* Optimal reading width for text */
}

/* Supplies and Steps List */
.supplies,
.steps {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none; /* Remove default list styling */
}

/* Responsive Design for smaller screens */
/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .modal-content {
    margin: 10% auto; /* Larger margin on top and bottom */
    width: 95%; /* Takes more width */
  }

  .service-details {
    flex-direction: column; /* Stacks image and text vertically */
    align-items: center; /* Center align items */
  }

  .service-image,
  .service-text {
    max-width: 100%; /* Full width */
    margin-right: 0; /* Reset margin */
  }

  .header1 {
    font-size: 1.5rem; /* Slightly smaller font size for headers on mobile */
  }

  .sub-header,
  .sub-sub-header {
    font-size: 1.25rem; /* Adapt subheaders for mobile readability */
  }

  /* Ensuring buttons are easy to tap on mobile */
  .add-to-cart-btn {
    padding: 12px 20px;
    font-size: 1rem; /* Larger font size for button text */
  }
}

/* Additional hover effect for non-touch devices */
@media (hover: hover) {
  .service-details:hover {
    background: #ffffff; /* Light background on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
  }
}

@media (min-width: 1024px) {
  .service-details {
    justify-content: space-evenly; /* Even spacing on larger screens */
  }
}

.service-image-desktop {
  display: block; /* Show on desktop */
}

.service-image-mobile {
  display: none; /* Hide on mobile */
}

/* Media queries */
@media (max-width: 768px) {
  /* Adjust the max-width as needed for your design */
  .service-image-desktop {
    display: none; /* Hide on mobile */
  }

  .service-image-mobile {
    display: block; /* Show on mobile */
  }
}

.section-title {
  font-size: 2rem; /* Larger font size for the section title */
  color: #4a148c; /* Deep purple color for contrast */
  margin-bottom: 0.5rem; /* Spacing between title and subtitle */
  text-transform: uppercase; /* Uppercase for emphasis */
  font-weight: 600; /* Bold font weight */
  padding-bottom: 0.5rem; /* Padding for underline effect */
  border-bottom: 3px solid #6a1b9a; /* Underline effect with brand color */
}

.section-subtitle {
  font-size: 1.5rem; /* Distinct size for subtitle */
  color: #6a1b9a; /* Brand color for harmony */
  font-weight: 400; /* Normal font weight for balance */
  margin-bottom: 2rem; /* Spacing below subtitle */
}

/* Hover effects for interactivity */
.section-title:hover,
.section-subtitle:hover {
  color: #9575cd; /* Lighter purple on hover for interactivity */
  cursor: pointer; /* Change cursor to suggest clickability */
}

.service-icon {
  width: 60px; /* Fixed width for the icon */
  height: 60px; /* Fixed height for the icon */
  margin-right: 20px; /* Spacing between the icon and the text */
  display: inline-block; /* Align with the text */
  vertical-align: middle; /* Align vertically with the text */
}

.service-icon img {
  max-width: 100%;
  height: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .section-title {
    font-size: 1.75rem; /* Slightly smaller on mobile */
  }
  .section-subtitle {
    font-size: 1.25rem; /* Smaller subtitle on mobile */
  }
}

/* Desktop-specific styles */
@media (min-width: 1024px) {
  .section-title {
    font-size: 2.25rem;
  }
  .section-subtitle {
    font-size: 1.75rem;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 1.5rem;
  }
  .section-subtitle {
    font-size: 1.2rem;
  }
}

/* Service Details - Desktop View */
.service-details {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center align vertically */
  gap: 20px; /* Spacing between elements */
  background: #fff; /* Background color */
  border-radius: 8px; /* Border radius for the container */
  padding: 20px; /* Padding around the content */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.service-icon {
  width: 80px; /* Larger width for desktop */
  height: 80px; /* Larger height for desktop */
}

.service-icon img {
  max-width: 100%; /* Ensure the image is fully contained within the icon space */
  height: auto;
}

.section-title {
  font-size: 2rem; /* Larger title size for desktop */
  margin-bottom: 0.25rem; /* Space between title and subtitle */
  color: #4a4a4a; /* Darker text for contrast */
  font-weight: bold; /* Bold text */
}

.section-subtitle {
  font-size: 1.25rem; /* Suitable subtitle size for desktop */
  color: #6a1b9a; /* Theme color */
}

/* Hover effect for the title and subtitle */
.section-title:hover,
.section-subtitle:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Media query for very large screens, if necessary */
@media (min-width: 1200px) {
  .service-icon {
    width: 100px; /* Further increase the size for very large screens */
    height: 100px;
  }

  .section-title {
    font-size: 2.5rem; /* Adjust title size for very large screens */
  }

  .section-subtitle {
    font-size: 1.5rem; /* Adjust subtitle size for very large screens */
  }
}


@keyframes clickedAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes initialAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

