body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

@keyframes zoomAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.header {
  /* Your existing styles for the header... */
  background-image: url("../ESC images/esc_header_bg.png"); /* Adjust the path as needed */
  background-color: #6a1b9a; /* Fallback color */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Adjust content spacing */
  align-items: center;
  padding: 20px 0;
  position: relative;
  animation: zoomAnimation 10s ease-in-out infinite;
  height: 240px;
}

.header:hover {
  animation-play-state: paused;
}

.nav {
  position: absolute;
  left: 100px;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  z-index: 2;
}

.nav a {
  color: #fff;
  text-decoration: none;
  padding: 15px;
}

.active-page {
  color: #ddd !important;
  text-decoration: underline !important;
  text-underline-offset: 0.3em !important;
}

.nav a:hover {
  color: #ddd;
}

.social-icons {
  position: absolute;
  bottom: 0;
  right: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  z-index: 2;
}

.social-icons a {
  color: #fff; /* Icon color */
  margin: 10px 0; /* Spacing between icons */
  font-size: 20px; /* Icon size */
}

@media (max-width: 540px) {
  .social-icons {
    top: 10px; /* Adjust position as needed */
    left: 0; /* Adjust position as needed */
    flex-direction: row; /* Change to horizontal layout */
    height: auto;
    width: 100%; /* Adjust height for horizontal layout */
    align-items: start;
  }
  .social-icons a {
    margin: 10px 10px; /* Spacing between icons */
  }

  .nav {
    top: auto;
    left: 0;
    bottom: 0;
    flex-direction: row;
    height: auto;
    width: 100%;
    z-index: 10;
  }

  .nav a {
    padding: 15px 10px; /* Increase tap area */
    font-size: 16px; /* Increase font size for better readability */
  }
}

@media (max-width: 280px) {
  .nav {
    font-size: 12px;
    font-weight: bold;
  }
  .header {
    /* Your existing styles for the header... */
    /* background-image: url("../ESC images/esc_header_bg2.png"); */
  }
}

.social-icons a:hover {
  color: #ddd; /* Icon hover color */
}

.service-intro {
  text-align: center;
  margin: 20px 0;
  color: #333;
  height: 30%;
}

.service-intro img {
  max-width: 100%; /* Ensure it doesn't overflow the container */
  height: 30%; /* Maintain the aspect ratio of the image */
}

.service-intro h2 {
  color: #6a1b9a; /* Deep purple */
  margin-bottom: 0.5em;
}

.service-intro p {
  color: #666;
  max-width: 80%;
  margin: auto; /* Centering the paragraph */
  padding-bottom: 20px;
}

.footer {
  background-color: #ff7043; /* Warm orange */
  color: #fff;
  text-align: center;
  padding: 5px 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer a {
  text-decoration: none;
  color: #fff;
}
.footer p {
  margin: 0;
}

/* Cookie Consent Banner Styles */
.cookie-consent-banner {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f4f4f4;
  padding: 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* High z-index to ensure it appears above other content */
  display: none; /* Hidden by default */
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cookie-consent-banner p {
  margin: 0 40px 0 0;
  padding: 5px;
}

.cookie-consent-banner button {
  margin-left: 10px;
}

/* Cookie Modal Styles */
.cookie-modal {
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.cookie-modal-content {
  background-color: #f4f4f4;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.close-modal {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-modal:hover,
.close-modal:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.close-learn-more {
  float: right; /* Position close button to the right */
  font-size: 25px; /* Adjust size as needed */
  cursor: pointer; /* Change cursor on hover */
}

.close-learn-more:hover {
  color: #000; /* Change color on hover */
}

.cookie-option {
  margin: 10px 0;
}

/* Style for the Save Preferences button */
#save-cookie-settings {
  background-color: #6a1b9a; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
}

#save-cookie-settings:hover {
  background-color: white;
  color: #6a1b9a;
  border: 2px solid #6a1b9a; /* Deep purple border on hover */
}
