.overview {
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 20px;
  gap: 20px; /* Adds space between image and content */
}

.overview-card {
  position: relative;
  display: flex;
  align-items: flex-start; /* Adjusted for better alignment */
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.overview-card:hover {
  transform: translateY(-10px); /* Slight raise effect on hover */
}

.overview-image,
.overview-content {
  height: auto;
  flex: 1;
  padding: 20px; /* Increased padding for better spacing */
  transition: background-color 0.3s ease; /* Smooth background transition */
}

.overview-image img {
  width: 100%;
  border-radius: 5px; /* Rounded corners for images */
}

.overview-content {
  margin: 20px;
  text-align: left;
  background-color: #ffffff;
  padding: 30px; /* More padding for content area */
  border-radius: 5px; /* Consistent rounded corners */
  height: 100%;
}

/* Enhancing typography and spacing */
.overview-content h2,
.overview-content p {
  margin-bottom: 20px;
  line-height: 1.6; /* Improved line spacing for readability */
}

.overview-content p {
  color: #666;
}

.overview-content h2 {
  color: #6a1b9a;
  font-size: 2rem; /* Larger font size for headings */
}

.moving-image-container {
  position: absolute; /* Absolute position within the card */
  top: 0;
  left: 0;
  width: 100%; /* Full width of the card */
  height: 100%; /* Adjust this depending on how much of the card the image should move within */
  overflow: hidden; /* Keeps the image within the bounds of the container */
  display: flex; /* To help center the image */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.moving-image {
  width: 10%; /* Adjust this to scale the size of your mascot image */
  height: auto; /* Keeps the aspect ratio of the image */
  position: absolute;
  animation: figureEight 10s infinite; /* Animation to move the image */
}

@keyframes figureEight {
  0%,
  100% {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  12.5% {
    top: 25%;
    left: 75%;
    transform: translate(-50%, -50%);
  }
  25% {
    top: 50%;
    left: 100%;
    transform: translate(-100%, -50%);
  }
  37.5% {
    top: 75%;
    left: 75%;
    transform: translate(-50%, -50%);
  }
  50% {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  62.5% {
    top: 75%;
    left: 25%;
    transform: translate(-50%, -50%);
  }
  75% {
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
  87.5% {
    top: 25%;
    left: 25%;
    transform: translate(-50%, -50%);
  }
}

.values-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: 20px;
  background: #fff;
}

.value-card {
  flex-basis: 30%;
  margin: 20px;
  padding: 20px;
  box-sizing: border-box;
  background: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-left: 4px solid #6a1b9a;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
}

.value-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.icon-container {
  height: 100px;
  width: 100px;
  margin: 0 auto 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

.value-card h3 {
  color: #6a1b9a;
  margin: 0 0 15px 0;
  font-size: 1.5rem;
}

.value-card p {
  color: #666;
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.4;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.value-card.active p {
  max-height: 500px; /* Adjust as needed based on content size */
  /* You can set a specific max-height or calculate it dynamically with JavaScript */
}

.mission {
  background-image: url("../ESC images/Mission.jpg");
}

.vision {
  background-image: url("../ESC images/Vision.jpg");
}

.values {
  background-image: url("../ESC images/value.jpg");
}

.founder-section {
  /* background: url("path_to_background_texture_or_image.jpg") no-repeat center center; */
  background-size: cover;
  padding: 50px 20px;
  text-align: center;
  position: relative;
  color: #444; /* Adjust the color to fit your brand */
}

.founder-image-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 30px;
  box-shadow: 2px 4px 10px #6a1b9a; /* Optional: Adds a shadow to the image */
}

.founder-image {
  max-width: 100%;
  height: auto;
  display: block;
  object-fit: cover; /* Ensures the image covers the container */
}

.founder-info {
  background: rgba(
    255,
    255,
    255,
    0.8
  ); /* Optional: Adds a semi-transparent overlay for readability */
  padding: 30px;
  width: auto;
  border-radius: 8px; /* Softens the corners of the content background */
  display: inline-block; /* Keeps the background tight around the content */
  border-left: 4px solid #6a1b9a;
}

.founder-info h2,
.founder-info .founder-name {
  color: #6a1b9a; /* Adjust the color to match your brand */
}

.founder-info .founder-name {
  font-weight: bold;
  font-size: 1.25em;
  margin-bottom: 10px;
}

.founder-info blockquote {
  font-style: italic;
  border-left: 4px solid #6a1b9a; /* Adds a vertical line to the left of the quote */
  padding-left: 20px;
  text-align: center;
  margin-top: 20px;
}

.map-container {
  position: relative;
  width: 94%; /* Use full width on mobile */
  height: 450px; /* Set a fixed height or use vh units */
  margin: 20px auto; /* Adjust as necessary */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 1px 10px #6a1b9a;
}

.map-overlay {
  position: absolute;
  width: 47%;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 20px;
  text-align: center; /* Center text for mobile view */
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  transition: transform 0.5s ease-in-out, opacity 0.5s ease;
  backface-visibility: hidden;
}

.map-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../ESC images/Eg-Map.png") no-repeat center center;
  background-size: cover;
  opacity: 1; /* Adjust the opacity as needed */
  z-index: -1;
}

.map-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* This will create a dark overlay on top of the image */
  z-index: -1;
}

.gmap_canvas {
  position: relative;
  height: 100%;
}

iframe#gmap_canvas {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  border: none;
  z-index: 1; /* Below the overlay initially */
}

.contact-section::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5); /* Adjust color and opacity as needed */
  z-index: -1; /* Place it behind the content */
  border-radius: 8px; /* Match your container's border radius */
}

.contact-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px;
  background-image: url("../ESC images/contact-us.png");
  background-size: cover; /* 'cover' to fill the area without stretching */
  background-position: left;
  background-repeat: no-repeat;
  position: relative; /* Needed for the pseudo-element to work */
  color: #fff; /* White text for legibility */
  border-left: 4px solid #6a1b9a; /* Brand purple color */
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px; /* Adding border-radius here */
  overflow: hidden; /* This ensures the pseudo-element respects the border-radius */
}

.contact-info {
  padding: 20px;
  background: rgba(
    255,
    255,
    255,
    0.5
  ); /* Semi-transparent background for contact info */
  border-radius: 8px;
  width: 80%;
  /* Rest of your styles */
}

.contact-info h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #6a1b9a; /* Brand purple color */
}

.info-sub {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info p {
  font-family: "Open Sans", Arial, sans-serif; /* Example of a web-friendly font */
  font-size: 1.1rem; /* Slightly larger than 1rem */
  font-weight: 500; /* Normal font weight, but could try 300 for lighter or 500 for bolder */
  margin: 10px 0;
  line-height: 1.6; /* Slightly more line spacing */
  color: #333; /* Dark grey text for legibility */
  letter-spacing: 0.01em; /* Slight letter spacing */
  text-align: left; /* Left-aligned text */
}

.info i {
  margin-right: 10px;
  color: #ff7043; /* Brand orange color */
}

.contact-form-container {
  width: 50%;
  background: #6a1b9a; /* Brand purple color */
  padding: 30px;
  border-radius: 8px;
}

#contact-form {
  display: flex;
  flex-direction: column;
}

#contact-form label {
  color: #fff; /* White color for labels for better readability */
  margin-bottom: 10px;
}

#contact-form input[type="text"],
#contact-form input[type="email"],
#contact-form input[type="tel"],
#contact-form select,
#contact-form textarea {
  width: calc(100% - 20px); /* Subtract padding from width */
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd; /* Light grey border */
  background-color: #fff; /* White background for input fields */
  color: #333; /* Dark text for readability */
}

#contact-form button {
  background-color: #ff7043; /* Brand orange color */
  color: #fff; /* White text */
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

#contact-form button:hover {
  background-color: #e69500; /* Darker orange on hover */
}

fieldset {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the space between fields as needed */
}

/* This ensures each field takes up 50% of the fieldset width minus the gap
fieldset input[type="text"] {
  flex: 0 0 calc(47% - 2p0px);
} */

/* Custom checkbox styles */
.custom-checkbox {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  color: #fff; /* Text color */
}

/* Hide the original checkbox */
.custom-checkbox input[type="checkbox"] {
  visibility: hidden;
  position: absolute;
}

/* Custom box for the custom checkbox */
.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee; /* Box color */
  border-radius: 5px; /* Rounded corners for the custom checkbox */
}

/* When the checkbox is checked, change the color of the box */
.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: #6a1b9a; /* Checked state color, use your brand purple */
}

/* The checkmark/indicator (hidden when not checked) */
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input[type="checkbox"]:checked + .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Adjusting the submit button */
#contact-form button {
  background-color: #ff7043; /* Brand orange color */
  color: #fff;
  padding: 15px 30px; /* Larger padding for the button */
  margin-top: 10px; /* Space between the checkbox and the button */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto; /* Auto width to fit the text */
  display: block; /* Block display to occupy its own line */
}

/* Responsive Design for Contact Section */
@media (max-width: 768px) {
  .contact-info {
    text-align: center;
  }
  .contact-section {
    flex-direction: column;
    margin: 50px auto; /* Center the section on smaller screens */
    width: 90%;
  }

  .contact-form-container {
    width: 80%;
  }

  fieldset input[type="text"] {
    flex-basis: 90%;
  }
}

/* Responsive Design for Map Overlay */
@media screen and (max-width: 768px) {
  .map-overlay {
    width: auto;
  }

  .map-overlay.active {
    transform: rotateY(180deg); /* Rotate out only the overlay */
    opacity: 0; /* Make the overlay transparent */
    z-index: 0; /* Place it behind the map when active */
  }

  .gmap_canvas.active {
    z-index: 2; /* Above the overlay when active */
  }

  /* Ensure the map canvas is clickable */
  .gmap_canvas:not(.active) iframe {
    pointer-events: none;
  }

  /* Ensure the map canvas is interactable when active */
  .gmap_canvas.active iframe {
    pointer-events: auto;
  }

  iframe#gmap_canvas {
    width: 100%;
  }
}
/* Responsive Design for Values Section */
@media (max-width: 960px) {
  .values-section {
    flex-direction: column;
  }

  .value-card {
    flex-basis: auto;
    width: 80%;
    margin-bottom: 40px;
  }
}

/* Responsiveness for Overview Section */
@media (max-width: 1024px) {
  .overview-card {
    flex-direction: column-reverse; /* Image on top when stacked */
    padding: 20px;
  }

  .overview-image,
  .overview-content {
    padding: 0px;
    margin: 0px;
  }

  .overview-content {
    padding: 5px;
    order: 1; /* Ensure content is above image on mobile */
  }
}

@media (max-width: 768px) {
  .overview-card {
    flex-direction: column-reverse;
    gap: 10px; /* Reduced gap on smaller screens */
  }

  /* Adjust animation for mobile screens */
  @keyframes figureEight {
    0%,
    100% {
      top: 0;
      left: 0;
    }
    25% {
      top: 0;
      left: 80%;
    }
    50% {
      top: 70%;
      left: 80%;
    }
    75% {
      top: 70%;
      left: 0;
    }
  }
}


body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

@keyframes zoomAnimation {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.header {
  /* Your existing styles for the header... */
  background-image: url('../ESC images/esc_header_bg.png'); /* Adjust the path as needed */
  background-color: #6A1B9A; /* Fallback color */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Adjust content spacing */
  align-items: center;
  padding: 20px 0;
  position: relative;
  animation: zoomAnimation 10s ease-in-out infinite; 
  height: 240px;
}

.header:hover {
  animation-play-state: paused;
}


.nav {
  position: absolute;
  left: 100px;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  z-index: 2;
}

.nav a {
  color: #fff;
  text-decoration: none;
  padding: 15px;
}


.active-page{
  color: #ddd !important;
  text-decoration: underline  !important;
  text-underline-offset: 0.3em !important;
}

.nav a:hover {
  color: #ddd;
}

.social-icons {
  position: absolute;
  bottom: 0;
  right: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  z-index: 2;
}

.social-icons a {
  color: #fff; /* Icon color */
  margin: 10px 0; /* Spacing between icons */
  font-size: 20px; /* Icon size */
}

@media (max-width: 540px) {

  .social-icons {
      top: 10px; /* Adjust position as needed */
      left: 0; /* Adjust position as needed */
      flex-direction: row; /* Change to horizontal layout */
      height: auto;
      width: 100%; /* Adjust height for horizontal layout */
      align-items: start;
  }
  .social-icons a {
      margin: 10px 10px; /* Spacing between icons */
  }

  .nav{
      top: auto;
      left: 0;
      bottom: 0;
      flex-direction: row;
      height: auto;
      width: 100%;
      z-index: 10;
  }

  .nav a {
      padding: 15px 10px; /* Increase tap area */
      font-size: 16px; /* Increase font size for better readability */
  }
  
}

@media (max-width: 280px) {
  .nav{
      font-size: 12px;
      font-weight: bold;
  }
  .header {
  /* Your existing styles for the header... */
  /* background-image: url('../ESC images/esc_header_bg2.png');  */
  }
}

.social-icons a:hover {
  color: #ddd; /* Icon hover color */
}

.service-intro {
  text-align: center;
  margin: 20px 0;
  color: #333;
  height: 30%;
}

.service-intro img {
  max-width: 100%; /* Ensure it doesn't overflow the container */
  height: 30%; /* Maintain the aspect ratio of the image */
}


.service-intro h2 {
  color: #6A1B9A; /* Deep purple */
  margin-bottom: 0.5em;
}

.service-intro p {
  color: #666;
  max-width: 80%;
  margin: auto; /* Centering the paragraph */
  padding-bottom: 20px;
}



.footer {
  background-color: #FF7043; /* Warm orange */
  color: #fff;
  text-align: center;
  padding: 5px 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer a{
  text-decoration: none;
  color: #fff;
}
.footer p{
  margin: 0;
}


/* Cookie Consent Banner Styles */
.cookie-consent-banner {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f4f4f4;
  padding: 20px;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.2);
  z-index: 1000; /* High z-index to ensure it appears above other content */
  display: none; /* Hidden by default */
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cookie-consent-banner p {
  margin: 0 40px 0 0;
  padding: 5px;
}

.cookie-consent-banner button {
  margin-left: 10px;
}



/* Cookie Modal Styles */
.cookie-modal {
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.cookie-modal-content {
  background-color: #f4f4f4;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.close-modal {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-modal:hover,
.close-modal:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.close-learn-more {
  float: right; /* Position close button to the right */
  font-size: 25px; /* Adjust size as needed */
  cursor: pointer; /* Change cursor on hover */
}

.close-learn-more:hover {
  color: #000; /* Change color on hover */
}

.cookie-option {
  margin: 10px 0;
}

/* Style for the Save Preferences button */
#save-cookie-settings {
  background-color: #6A1B9A; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
}

#save-cookie-settings:hover {
  background-color: white;
  color: #6A1B9A;
  border: 2px solid #6A1B9A; /* Deep purple border on hover */
}
